import React from 'react';

import { IconsEnum } from '../../../../../../../../../assets/icons/types';
import { ItemMessagesListResultsMessageResultFileFileAttachment } from './ItemMessagesListResultsMessageResultFile.types';
import { TaskNanoID } from '../../../../../../../../tasks/tasksTypes';
import { FetchMessagesCacheKey } from '../../../../../../../messagesTypes';

import { ItemMessagesListResultsMessageResultFileDropdown } from './components/ItemMessagesListResultsMessageResultFileDropdown';
import { ModelViewerModalButton } from '../../../../../../../../modelViewers/components/modalButtons/ModelViewerModalButton';

import { LinkHelper } from '../../../../../../../../../helpers/links/LinkHelper';
import { Icon } from '../../../../../../../../../helpers/Icon';
import { SizeHelper } from '../../../../../../../../../helpers/SizeHelper';
import { DateHelper } from '../../../../../../../../../helpers/DateHelper';
import { CheckPermissions } from '../../../../../../../../../helpers/CheckPermissions';

import { getFileAttachmentName } from '../../../../../../../../fileAttachments/utils/getFileAttachmentName';

import { words } from '../../../../../../../../../locales/keys';
import { Files } from '../../../../../../../../../utils/Files';
import { TasksPermissions } from '../../../../../../../../tasks/tasksConstants';

interface ItemMessagesListResultsMessageResultFileProps {
  fileAttachments: ItemMessagesListResultsMessageResultFileFileAttachment[];
  fileAttachment: ItemMessagesListResultsMessageResultFileFileAttachment;
  taskNanoId: TaskNanoID;
  messagesCacheKey: FetchMessagesCacheKey;
}

function ItemMessagesListResultsMessageResultFile({
  fileAttachments,
  fileAttachment,
  taskNanoId,
  messagesCacheKey
}: ItemMessagesListResultsMessageResultFileProps) {
  const withView3dModel = Files.isPreview3dModel(fileAttachment.file);

  return (
    <div className="flex text-xs sm:text-sm space-x-1.5 items-center group relative">
      <div>
        <Icon
          icon={IconsEnum.DOCUMENT_OUTLINE}
          className="h-4 w-4 sm:h-5 sm:w-5"
        />
      </div>
      <LinkHelper
        className="flex flex-1 leading-relaxed overflow-hidden"
        href={fileAttachment.file}
        target="_blank"
      >
        <span className="truncate">
          {getFileAttachmentName(fileAttachment)}
        </span>
      </LinkHelper>
      <div className="w-16 sm:w-20 text-right whitespace-nowrap">
        <SizeHelper size={fileAttachment.size} />
      </div>
      <div className="w-20 sm:w-28 text-right whitespace-nowrap">
        <DateHelper date={fileAttachment.createdAt} />
      </div>
      <div className="hidden group-hover:flex absolute left-0 right-8 pointer-events-none">
        <div className="flex-1 bg-gradient-to-l from-white dark:from-gray-900"></div>
        <div className="pointer-events-auto">
          {withView3dModel && (
            <CheckPermissions
              action={TasksPermissions.READ_TASKS_RESULTS_VIEW_3D_MODEL_BUTTON}
            >
              <ModelViewerModalButton
                className="py-1 pl-2 pr-2 rounded inline-flex items-center whitespace-nowrap text-sm font-medium leading-none focus:ring-base border-gray-400 bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 border relative z-5"
                i18nText={words.view3DModel}
                initialModelIndex={fileAttachments.findIndex(
                  (file) => fileAttachment.id === file.id
                )}
                models={fileAttachments}
                cameraControls
                withDownloadButton
              />
            </CheckPermissions>
          )}
        </div>
      </div>
      <ItemMessagesListResultsMessageResultFileDropdown
        fileAttachments={fileAttachments}
        fileAttachment={fileAttachment}
        taskNanoId={taskNanoId}
        messagesCacheKey={messagesCacheKey}
      />
    </div>
  );
}

export default ItemMessagesListResultsMessageResultFile;
