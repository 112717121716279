import { Fragment, useEffect, useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';

import { PositionStyleEnum } from '../../../../../../../types';
import { TaskNanoID } from '../../../../../../tasks/tasksTypes';
import { ProjectNanoID } from '../../../../../../projects/projectsTypes';
import {
  FetchMessagesSortTypes,
  MessageMessageTypes
} from '../../../../../messagesTypes';

import {
  FETCH_SELECT_FIELD_FIN_MESSAGES,
  FetchSelectFieldFinMessagesQueryResponse
} from '../../../../../queries/fetchSelectFieldFinMessages.query';

import { useMessages } from '../../../../../hooks/useMessages';

import {
  ItemMessagesFilters,
  ItemMessagesListFilterMessages,
  ItemMessagesListLastMessage,
  ItemMessagesListMessages,
  ItemMessagesListMessagesFetched
} from '../../../../list/ItemMessagesList';

import { useJumpToMessage } from '../../../../list/ItemMessagesList/hooks/useJumpToMessage';

import { DropdownHelper } from '../../../../../../../helpers/dropdowns/DropdownHelper';
import { PopoverPlacement } from '../../../../../../../helpers/Popover/popoverConstants';
import { PureButtonHelper } from '../../../../../../../helpers/buttons/PureButtonHelper';
import { LoadingSkeleton } from '../../../../../../../helpers/LoadingSkeleton';

import { TaskCache } from '../../../../../../tasks/TaskCache';
import { ProjectCache } from '../../../../../../projects/ProjectCache';

import { messagesKeys, words } from '../../../../../../../locales/keys';

interface ItemMessagesHeaderJumpToButtonProps {
  taskNanoId?: TaskNanoID;
  projectNanoId?: ProjectNanoID;
  messages: ItemMessagesListMessages;
  messagesFetched: ItemMessagesListMessagesFetched;
  messagesFilters: ItemMessagesFilters;
  filterMessages: ItemMessagesListFilterMessages;
  lastMessages: ItemMessagesListLastMessage[];
  lastMessagesLoading: boolean;
}

function ItemMessagesHeaderJumpToButton({
  projectNanoId,
  taskNanoId,
  messages,
  messagesFetched,
  messagesFilters,
  filterMessages,
  lastMessages,
  lastMessagesLoading
}: ItemMessagesHeaderJumpToButtonProps) {
  const versionMessagesFilters = useMemo<ItemMessagesFilters>(
    () => ({
      ...omit(messagesFilters, 'id'),
      taskNanoId,
      messageType: MessageMessageTypes.VERSION
    }),
    [messagesFilters, taskNanoId]
  );

  const {
    messages: versionMessages,
    messagesError: versionMessagesError,
    messagesLoading: versionMessagesLoading,
    changeMessagesFilters: changeVersionMessagesFilters
  } = useMessages<FetchSelectFieldFinMessagesQueryResponse>({
    cacheKey: TaskCache.versionMessagesCacheKey(taskNanoId),
    query: FETCH_SELECT_FIELD_FIN_MESSAGES,
    initialFilters: versionMessagesFilters,
    initialSort: [FetchMessagesSortTypes.CREATED_AT_ASC],
    options: {
      enabled: !!taskNanoId,
      enabledPlaceholder: !!taskNanoId
    }
  });

  useEffect(() => {
    if (taskNanoId) {
      changeVersionMessagesFilters(versionMessagesFilters);
    }
  }, [changeVersionMessagesFilters, taskNanoId, versionMessagesFilters]);

  const firstMessagesFilters = useMemo<ItemMessagesFilters>(
    () => ({
      ...omit(messagesFilters, 'id'),
      taskNanoId,
      projectNanoId
    }),
    [messagesFilters, projectNanoId, taskNanoId]
  );

  const {
    messages: fistMessages,
    messagesError: fistMessagesError,
    messagesLoading: fistMessagesLoading,
    changeMessagesFilters: changeFirstMessagesFilters
  } = useMessages<FetchSelectFieldFinMessagesQueryResponse>({
    cacheKey: projectNanoId
      ? ProjectCache.firstMessagesCacheKey(projectNanoId)
      : TaskCache.firstMessagesCacheKey(taskNanoId),
    query: FETCH_SELECT_FIELD_FIN_MESSAGES,
    initialFilters: firstMessagesFilters,
    initialSort: [FetchMessagesSortTypes.CREATED_AT_ASC],
    initialLimit: 1
  });

  useEffect(() => {
    changeFirstMessagesFilters(firstMessagesFilters);
  }, [changeFirstMessagesFilters, firstMessagesFilters]);

  const { jumpToMessage, jumpToLastMessage } = useJumpToMessage({
    filterMessages,
    messages,
    messagesFetched,
    messagesFilters
  });

  if (
    isEmpty(versionMessages) &&
    isEmpty(fistMessages) &&
    isEmpty(lastMessages)
  ) {
    return null;
  }

  return (
    <DropdownHelper
      className="flex items-center justify-center"
      buttonClassName="py-1 pl-1 pr-1 sm:pl-2 sm:pr-2 rounded inline-flex items-center shrink-0 text-xs leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white focus:ring-offset-0"
      popoverClassName="relative min-w-48 z-10 overflow-y-auto bg-white border border-transparent dark:bg-gray-700 dark:border-opacity-10 dark:border-white dark:text-white focus:outline-none py-1 ring-1 ring-black ring-opacity-5 rounded-md shadow-lg text-gray-700"
      dropdownPlacement={PopoverPlacement.BOTTOM_START}
      i18nText={messagesKeys.jumpTo}
      popoverPositionStyle={PositionStyleEnum.fixed}
    >
      <LoadingSkeleton
        loaded={
          (!versionMessagesLoading || !taskNanoId) &&
          !fistMessagesLoading &&
          !lastMessagesLoading
        }
        height={20}
        width={120}
        className="m-0"
      >
        {fistMessages && (
          <PureButtonHelper
            className="flex items-center text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-3 3xl:px-4 py-1.5 3xl:py-2 text-xs 3xl:text-sm w-full whitespace-nowrap"
            i18nText={words.start}
            onClick={() => jumpToMessage(fistMessages[0])}
          />
        )}

        {!isEmpty(versionMessages) && (
          <Fragment>
            <hr className="border-gray-200 dark:border-gray-600 mx-2.5 my-1.5" />

            {versionMessages.map((message) => (
              <PureButtonHelper
                key={message.uuid}
                className="flex items-center text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-3 3xl:px-4 py-1.5 3xl:py-2 text-xs 3xl:text-sm w-full whitespace-nowrap"
                text={message.body}
                onClick={() => jumpToMessage(message)}
              />
            ))}

            <hr className="border-gray-200 dark:border-gray-600 mx-2.5 my-1.5" />
          </Fragment>
        )}

        {lastMessages && (
          <PureButtonHelper
            className="flex items-center text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-3 3xl:px-4 py-1.5 3xl:py-2 text-xs 3xl:text-sm w-full whitespace-nowrap"
            i18nText={words.latest}
            onClick={() => jumpToLastMessage(lastMessages[0])}
          />
        )}
      </LoadingSkeleton>

      {(versionMessagesError || fistMessagesError) && (
        <p className="mt-2 text-sm text-red-600">
          {versionMessagesError || fistMessagesError}
        </p>
      )}
    </DropdownHelper>
  );
}

export default ItemMessagesHeaderJumpToButton;
