import React, { useCallback, useEffect, useMemo } from 'react';
import omit from 'lodash/omit';

import {
  TaskNanoID,
  TaskProjectUUID,
  TaskUUID
} from '../../../../../tasksTypes';
import {
  FetchMessagesFilters,
  MessageID
} from '../../../../../../messages/messagesTypes';
import { CheckableMessagesProps } from '../../../../../../../common/hooks/useMessagesCheckable';

import {
  FETCH_USERS_MESSAGES_AUTHORS_QUERY,
  FetchUsersMessagesAuthorsQueryResponse
} from '../../../../../../users/queries/fetchUsersMessagesAuthors.query';
import {
  FETCH_SELECT_FIELD_FIN_MESSAGES,
  FetchSelectFieldFinMessagesQueryResponse
} from '../../../../../../messages/queries/fetchSelectFieldFinMessages.query';

import { useFinUsers } from '../../../../../../users/hooks/useFinUsers';
import { useJumpToMessage } from '../../../../../../messages/components/list/ItemMessagesList/hooks/useJumpToMessage';
import { useMessages } from '../../../../../../messages/hooks/useMessages';
import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';

import {
  ItemMessagesList,
  ItemMessagesListHasNextMessagesPage,
  ItemMessagesListLoadMoreMessages,
  ItemMessagesListMessages,
  ItemMessagesListMessagesErrorMessage,
  ItemMessagesListMessagesFetched,
  ItemMessagesListMessagesFetchingNextPage,
  ItemMessagesListMessagesIsPlaceholderData,
  ItemMessagesListOnRemoveSendingMessage
} from '../../../../../../messages/components/list/ItemMessagesList';
import { ItemMessagesHeader } from '../../../../../../messages/components/headers/ItemMessagesHeader';
import { PinnedMessageWithTodoList } from '../../../../../../messages/components/list/PinnedMessageWithTodoList';

import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';

import { TaskCache } from '../../../../../TaskCache';
import { UserCache } from '../../../../../../users/UserCache';

import { MessagesPermissions } from '../../../../../../messages/messagesConstants';

import { messagesKeys } from '../../../../../../../locales/keys';

type ItemMessagesListProps = {
  taskNanoId: TaskNanoID;
  projectUuid: TaskProjectUUID;
  taskUuid: TaskUUID;
  onReplyMessage?: (messageId: MessageID) => void;
  sendingMessages: ItemMessagesListMessages;
  onRemoveSendingMessage: ItemMessagesListOnRemoveSendingMessage;
  togglePinnedMessagesView: () => void;
  pinnedMessagesView: boolean;

  messages: ItemMessagesListMessages;
  messagesFetched: ItemMessagesListMessagesFetched;
  messagesFetchingNextPage: ItemMessagesListMessagesFetchingNextPage;
  messagesError: ItemMessagesListMessagesErrorMessage;
  messagesIsPlaceholderData: ItemMessagesListMessagesIsPlaceholderData;
  messagesFetchingPreviousPage: ItemMessagesListMessagesFetchingNextPage;
  hasPreviousMessagesPage: ItemMessagesListHasNextMessagesPage;
  loadMorePreviousMessages: ItemMessagesListLoadMoreMessages;
  hasNextMessagesPage: ItemMessagesListHasNextMessagesPage;
  loadMoreMessages: ItemMessagesListLoadMoreMessages;
  changeMessagesFilters: (
    changedFilters: FetchMessagesFilters,
    removeFilters?: string[]
  ) => void;
  messagesFilters: FetchMessagesFilters;
  filterMessages: (nextFilters: FetchMessagesFilters) => void;
} & CheckableMessagesProps;

function TaskMessagesContentMessagesList({
  taskNanoId,
  projectUuid,
  taskUuid,
  onReplyMessage,
  sendingMessages,
  onRemoveSendingMessage,
  togglePinnedMessagesView,
  pinnedMessagesView,
  checkedMessages,
  onSetCheckedMessage,
  messages,
  messagesError,
  messagesFetched,
  messagesFetchingNextPage,
  messagesFetchingPreviousPage,
  messagesIsPlaceholderData,
  hasNextMessagesPage,
  hasPreviousMessagesPage,
  loadMoreMessages,
  loadMorePreviousMessages,
  changeMessagesFilters,
  messagesFilters,
  filterMessages
}: ItemMessagesListProps) {
  const currentUser = useCurrentUser();

  const lastMessagesFilters = useMemo<FetchMessagesFilters>(
    () => omit(messagesFilters, 'id'),
    [messagesFilters]
  );

  const {
    messages: lastMessages,
    messagesLoading: lastMessagesLoading,
    changeMessagesFilters: lastMessagesChangeFilters
  } = useMessages<FetchSelectFieldFinMessagesQueryResponse>({
    cacheKey: TaskCache.lastMessagesCacheKey(taskNanoId),
    query: FETCH_SELECT_FIELD_FIN_MESSAGES,
    initialFilters: lastMessagesFilters,
    initialLimit: 1
  });

  useEffect(() => {
    lastMessagesChangeFilters(lastMessagesFilters);
  }, [lastMessagesChangeFilters, lastMessagesFilters]);

  useEffect(() => {
    if (taskNanoId !== messagesFilters.taskNanoId) {
      changeMessagesFilters({
        taskNanoId
      });
    }
  }, [changeMessagesFilters, messagesFilters.taskNanoId, taskNanoId]);

  const { users, usersError, usersFetched, usersTotalCount } =
    useFinUsers<FetchUsersMessagesAuthorsQueryResponse>({
      cacheKey: UserCache.usersMessagesByTask(taskNanoId),
      query: FETCH_USERS_MESSAGES_AUTHORS_QUERY,
      initialFilters: {
        messagesInTaskNanoId: { eq: taskNanoId }
      },
      initialLimit: 100
    });

  const handleClickPinnedButton = useCallback<() => void>(() => {
    togglePinnedMessagesView();
    if (!pinnedMessagesView && !messagesFilters.pinned) {
      changeMessagesFilters({
        ...messagesFilters,
        pinned: true
      });
    }

    if (pinnedMessagesView && messagesFilters.pinned) {
      changeMessagesFilters({
        ...messagesFilters,
        pinned: false
      });
    }
  }, [
    changeMessagesFilters,
    messagesFilters,
    pinnedMessagesView,
    togglePinnedMessagesView
  ]);

  const { jumpToLastMessage, jumpToMessage } = useJumpToMessage({
    filterMessages,
    messages,
    messagesFetched,
    messagesFilters
  });

  const goToLastMessage = useCallback(() => {
    if (lastMessages.length > 0) {
      jumpToLastMessage(lastMessages[0]);
    }
  }, [jumpToLastMessage, lastMessages]);

  return (
    <>
      {pinnedMessagesView ? (
        <PinnedMessageWithTodoList
          i18nTitle={messagesKeys.pinnedMessages}
          onBackClick={handleClickPinnedButton}
          messages={messages}
          messagesErrorMessage={messagesError}
          messagesFetched={messagesFetched}
          messagesIsPlaceholderData={messagesIsPlaceholderData}
          taskNanoId={taskNanoId}
          messagesCacheKey={TaskCache.messagesCacheKey(taskNanoId)}
        />
      ) : (
        <ItemMessagesList
          messages={messages}
          messagesCacheKey={TaskCache.messagesCacheKey(taskNanoId)}
          messagesErrorMessage={messagesError}
          messagesFetched={messagesFetched}
          messagesFetchingNextPage={messagesFetchingNextPage}
          messagesFetchingPreviousPage={messagesFetchingPreviousPage}
          onReplyMessage={onReplyMessage}
          hasNextMessagesPage={hasNextMessagesPage}
          hasPreviousMessagesPage={hasPreviousMessagesPage}
          loadMoreMessages={loadMoreMessages}
          loadMorePreviousMessages={loadMorePreviousMessages}
          messagesIsPlaceholderData={messagesIsPlaceholderData}
          forwardMessageProjectUuid={projectUuid}
          forwardMessageTaskUuid={taskUuid}
          sendingMessages={sendingMessages}
          onRemoveSendingMessage={onRemoveSendingMessage}
          goToLastMessage={goToLastMessage}
          checkedMessages={checkedMessages}
          onSetCheckedMessage={onSetCheckedMessage}
          jumpToMessage={jumpToMessage}
          markedUnhappyMessages={
            !messagesFilters.visibleForClient &&
            currentUser.hasPermissions(
              MessagesPermissions.READ_MESSAGE_UNHAPPY_MESSAGE
            )
          }
        />
      )}

      <CheckPermissions action={MessagesPermissions.READ_MESSAGES_HEADER}>
        <ItemMessagesHeader
          key={taskNanoId}
          taskNanoId={taskNanoId}
          messagesFilters={messagesFilters}
          filterMessages={filterMessages}
          users={users}
          usersError={usersError}
          usersFetched={usersFetched}
          usersTotalCount={usersTotalCount}
          onClickPinnedButton={handleClickPinnedButton}
          messages={messages}
          messagesFetched={messagesFetched || messagesIsPlaceholderData}
          lastMessages={lastMessages}
          lastMessagesLoading={lastMessagesLoading}
        />
      </CheckPermissions>
    </>
  );
}

export default TaskMessagesContentMessagesList;
